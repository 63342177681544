interface CityListTypes {
  시: string[];
  '군/구': {
    [key: string]: string[];
  };
  동: {
    [key: string]: { [key: string]: string[] };
  };
}

const CITY_LIST: CityListTypes = {
  시: [
    '서울시',
    '인천시',
    '부산시',
    '광주시',
    '대전시',
    '울산시',
    '세종특별자치도',
    '경기도',
    '강원특별자치도',
    '충청북도',
    '전북특별자치도',
    '전라남도',
    '경상북도',
    '경상남도',
    '제주특별자치도',
  ],
  '군/구': {
    서울시: [
      '종로구',
      '중구',
      '용산구',
      '성동구',
      '광진구',
      '동대문구',
      '중랑구',
      '성북구',
      '강북구',
      '도봉구',
      '노원구',
      '은평구',
      '서대문구',
      '마포구',
      '양천구',
      '강서구',
      '구로구',
      '금천구',
      '영등포구',
      '동작구',
      '관악구',
      '서초구',
      '강남구',
      '송파구',
      '강동구',
    ],
    인천시: [
      '중구',
      '동구',
      '미추홀구',
      '연수구',
      '남동구',
      '부평구',
      '계양구',
      '서구',
      '강화군',
      '옹진군',
    ],
    부산시: [
      '중구',
      '서구',
      '동구',
      '영도구',
      '부산진구',
      '동래구',
      '남구',
      '북구',
      '강서구',
      '해운대구',
      '사하구',
      '금정구',
      '연제구',
      '수영구',
      '사상구',
      '기장군',
    ],
    광주시: ['동구', '서구', '남구', '북구', '광산구'],
    대전시: ['동구', '중구', '서구', '유성구', '대덕구'],
    울산시: ['중구', '남구', '동구', '북구', '울주군'],
    세종특별자치도: [
      '조치원읍',
      '연기면',
      '연동면',
      '부강면',
      '금남면',
      '장군면',
      '연서면',
      '전의면',
      '전동면',
      '소정면',
      '한솔동',
      '새롬동',
      '나성동',
      '도담동',
      '어진동',
      '해밀동',
      '아름동',
      '종촌동',
      '고운동',
      '소담동',
      '반곡동',
      '보람동',
      '대평동',
      '다정동',
    ],
    경기도: [
      '수원시',
      '성남시',
      '의정부시',
      '안양시',
      '부천시',
      '광명시',
      '평택시',
      '동두천시',
      '안산시',
      '고양시',
      '과천시',
      '구리시',
      '남양주시',
      '오산시',
      '시흥시',
      '군포시',
      '의왕시',
      '하남시',
      '용인시',
      '파주시',
      '이천시',
      '안성시',
      '김포시',
      '화성시',
      '광주시',
      '양주시',
      '포천시',
      '여주시',
      '연천군',
      '가평군',
      '양평군',
    ],
    강원특별자치도: [
      '춘천시',
      '원주시',
      '강릉시',
      '동해시',
      '태백시',
      '속초시',
      '삼척시',
      '홍천군',
      '횡성군',
      '영월군',
      '평창군',
      '정선군',
      '철원군',
      '화천군',
      '양구군',
      '인제군',
      '고성군',
      '양양군',
    ],
    충청북도: [
      '청주시',
      '충주시',
      '제천시',
      '보은군',
      '옥천군',
      '영동군',
      '증평군',
      '진천군',
      '괴산군',
      '음성군',
      '단양군',
    ],
    충청남도: [
      '천안시',
      '공주시',
      '보령시',
      '아산시',
      '서산시',
      '논산시',
      '계룡시',
      '당진시',
      '금산군',
      '부여군',
      '서천군',
      '청양군',
      '홍성군',
      '예산군',
      '태안군',
    ],
    전북특별자치도: [
      '전주시',
      '군산시',
      '익산시',
      '정읍시',
      '남원시',
      '김제시',
      '완주군',
      '진안군',
      '무주군',
      '장수군',
      '임실군',
      '순창군',
      '고창군',
      '부안군',
    ],
    전라남도: [
      '목포시',
      '여수시',
      '순천시',
      '나주시',
      '광양시',
      '담양군',
      '곡성군',
      '구례군',
      '고흥군',
      '보성군',
      '화순군',
      '장흥군',
      '강진군',
      '해남군',
      '영암군',
      '무안군',
      '함평군',
      '영광군',
      '장성군',
      '완도군',
      '진도군',
      '신안군',
    ],
    경상북도: [
      '포항시',
      '경주시',
      '김천시',
      '안동시',
      '구미시',
      '영주시',
      '영천시',
      '상주시',
      '문경시',
      '경산시',
      '군위군',
      '의성군',
      '청송군',
      '영양군',
      '영덕군',
      '청도군',
      '고령군',
      '성주군',
      '칠곡군',
      '예천군',
      '봉화군',
      '울진군',
      '울릉군',
    ],
    경상남도: [
      '창원시',
      '김해시',
      '양산시',
      '진주시',
      '거제시',
      '통영시',
      '사천시',
      '밀양시',
      '함안군',
      '창녕군',
      '고성군',
      '남해군',
      '하동군',
      '산청군',
      '함양군',
      '거창군',
      '합천군',
    ],
    제주특별자치도: ['제주시', '서귀포시'],
  },
  동: {
    서울시: {
      종로구: [
        '청운효자동',
        '사직동',
        '삼청동',
        '부암동',
        '평창동',
        '무악동',
        '교남동',
        '가회동',
        '종로동',
        '이화동',
        '혜화동',
        '창신동',
        '숭인동',
      ],
      중구: [
        '소공동',
        '회현동',
        '명동',
        '필동',
        '장충동',
        '광희동',
        '을지로동',
        '신당동',
        '다산동',
        '약수동',
        '청구동',
        '동화동',
        '황학동',
        '중림동',
      ],
      용산구: [
        '후암동',
        '용산동',
        '남영동',
        '청파동',
        '원효로동',
        '효창동',
        '용문동',
        '한강로동',
        '이촌동',
        '이태원동',
        '한남동',
        '서빙고동',
        '보광동',
      ],
      성동구: [
        '왕십리제동',
        '왕립리도선동',
        '마장동',
        '사근동',
        '행당동',
        '응봉동',
        '금호동',
        '옥수동',
        '성수동',
        '송정동',
        '용답동',
      ],
      광진구: [
        '중곡동',
        '능동',
        '광장동',
        '자양동',
        '구의제동',
        '군자동',
        '화양동',
      ],
      동대문구: [
        '용신동',
        '제기동',
        '전농동',
        '답십리동',
        '장안동',
        '청량리동',
        '회기동',
        '휘경동',
        '이문동',
      ],
      중랑구: ['면목동', '상봉동', '중화동', '묵동', '망우동', '신내동'],
      성북구: [
        '성북동',
        '삼선동',
        '동선동',
        '안암동',
        '보문동',
        '정릉동',
        '길음동',
        '종암동',
        '월곡동',
        '장위동',
        '석관동',
      ],
      강북구: [
        '삼양동',
        '미아동',
        '송중동',
        '송천동',
        '삼각산동',
        '번동',
        '수유동',
        '우이동',
        '인수동',
      ],
      도봉구: ['쌍문동', '방학동', '창동', '도봉동'],
      노원구: ['월계동', '공릉동', '하계동', '중계동', '상계동'],
      은평구: [
        '녹번동',
        '불광동',
        '갈현동',
        '구산동',
        '대조동',
        '응암동',
        '역촌동',
        '신사동',
        '증산동',
        '수색동',
        '진관동',
      ],
      서대문구: [
        '충현동',
        '천연동',
        '북아현동',
        '신촌동',
        '연희동',
        '홍제동',
        '홍은동',
        '남가좌동',
        '북가좌동',
      ],
      마포구: [
        '공덕동',
        '아현동',
        '도화동',
        '용강동',
        '대흥동',
        '염리동',
        '신수동',
        '서강동',
        '서교동',
        '합정동',
        '망원동',
        '연남동',
        '성산동',
        '상암동',
      ],
      양천구: ['목동', '신월동', '신정동'],
      강서구: [
        '염창동',
        '등촌동',
        '화곡동',
        '가양동',
        '발산동',
        '공항동',
        '방화동',
      ],
      구로구: [
        '신도림동',
        '구로동',
        '가리동',
        '고척동',
        '개봉동',
        '오류동',
        '항동',
        '수궁동',
      ],
      금천구: ['가산동', '독산동', '시흥동'],
      영등포구: [
        '영등포동',
        '여의동',
        '당산동',
        '도림동',
        '문래동',
        '양평동',
        '신길동',
        '대림동',
      ],
      동작구: ['노량진동', '상도동', '흑석동', '사당동', '대방동', '신대방동'],
      관악구: [
        '보라매동',
        '청림동',
        '성현동',
        '행운동',
        '낙성대동',
        '청룡동',
        '은천동',
        '중앙동',
        '인헌동',
        '남현동',
        '서원동',
        '신원동',
        '서림동',
        '신사동',
        '신림동',
        '난향동',
        '조원동',
        '대학동',
        '삼성동',
        '미성동',
        '난곡동',
      ],
      서초구: ['서초동', '잠원동', '반포동', '방배동', '양재동', '내곡동'],
      강남구: [
        '신사동',
        '논현동',
        '압구정동',
        '청담동',
        '삼성동',
        '대치동',
        '역삼동',
        '도곡동',
        '개포동',
        '일원동',
        '수서동',
        '세곡동',
      ],
      송파구: [
        '풍납동',
        '거여동',
        '마천동',
        '방이동',
        '오륜동',
        '오금동',
        '송파동',
        '석촌동',
        '삼전동',
        '가락동',
        '문정동',
        '장지동',
        '위례동',
        '잠실동',
      ],
      강동구: [
        '강일동',
        '상일동',
        '명일동',
        '고덕동',
        '암사동',
        '천호동',
        '성내동',
        '길동',
        '둔촌동',
      ],
    },
    인천시: {
      중구: [
        '연안동',
        '신포동',
        '신흥동',
        '도원동',
        '율목동',
        '동인천동',
        '개항동',
        '영종동',
        '영종동',
        '운서동',
        '용유동',
      ],
      동구: ['만석동', '화수동', '송림동', '송현동', '창영동', '금창동'],
      미추홀구: ['숭의동', '용현동', '학익동', '도화동', '주안동', '문학동'],
      연수구: ['옥련동', '선학동', '연수동', '청학동', '동춘동', '송도동'],
      남동구: [
        '구월동',
        '간석동',
        '만수동',
        '장수동',
        '서창동',
        '남촌도림동',
        '논현동',
        '호구포동',
      ],
      부평구: [
        '부평동',
        '십정동',
        '갈산동',
        '삼산동',
        '부개동',
        '일신동',
        '청천동',
        '산곡동',
        '삼산동',
      ],
      계양구: [
        '효성동',
        '계산동',
        '작전동',
        '서운동',
        '임학동',
        '병방동',
        '방축동',
        '박촌동',
        '귤현동',
        '동양동',
      ],
      서구: [
        '검암동',
        '경서동',
        '백석동',
        '청라동',
        '가정동',
        '석남동',
        '연희동',
        '가좌동',
        '마전동',
        '금곡동',
        '오류동',
        '왕길동',
        '불로동',
        '원당동',
        '대곡동',
      ],
      강화군: [
        '강화읍',
        '선원면',
        '불은면',
        '길상면',
        '화도면',
        '양도면',
        '내가면',
        '하점면',
        '양사면',
        '송해면',
        '교동면',
        '삼산면',
        '서도면',
      ],
      옹진군: [
        '북도면',
        '백령면',
        '대청면',
        '덕적면',
        '영흥면',
        '자월면',
        '연평면',
      ],
    },
    부산시: {
      중구: [
        '중앙동',
        '동광동',
        '대청동',
        '보수동',
        '부평동',
        '광복동',
        '남포동',
        '영주동',
      ],
      서구: [
        '동대신동',
        '서대신동',
        '부민동',
        '아미동',
        '초장동',
        '충무동',
        '남부민동',
        '암남동',
      ],
      동구: ['초량동', '수정동', '좌천동', '범일동'],
      영도구: ['남항동', '영선동', '봉래동', '청학동', '동삼동'],
      부산진구: [
        '부전동',
        '연지동',
        '초읍동',
        '양정동',
        '전포동',
        '부암동',
        '당감동',
        '가야동',
        '개금동',
        '범천동',
      ],
      동래구: [
        '수민동',
        '복산동',
        '명륜동',
        '온천동',
        '사직동',
        '안락동',
        '명장동',
      ],
      남구: ['대연동', '용호동', '용당동', '감만동', '문현동'],
      북구: ['구포동', '금곡동', '화명동', '덕천동', '만덕동'],
      강서구: ['대저동', '강동동', '명지동', '가락동', '녹산동', '가덕도동'],
      해운대구: [
        '우동',
        '중동',
        '좌동',
        '송정동',
        '반송동',
        '반여동',
        '재송동',
      ],
      사하구: [
        '괴정동',
        '당리동',
        '하단동',
        '신평동',
        '장림동',
        '다대동',
        '구평동',
        '감천동',
      ],
      금정구: [
        '서동',
        '금사동',
        '부곡동',
        '장전동',
        '구서동',
        '남산동',
        '청룡노포동',
        '금성동',
      ],
      연제구: ['거제동', '연산동'],
      수영구: ['남천동', '수영동', '망미동', '광안동', '민락동'],
      사상구: [
        '삼락동',
        '모라동',
        '덕포동',
        '괘법동',
        '감전동',
        '주례동',
        '학장동',
        '엄궁동',
      ],
      기장군: ['기장읍', '장안읍', '정관읍', '일광읍', '철마면'],
    },
    광주시: {
      동구: ['충장동', '동명동', '계림동', '산수동', '지산동', '서남동'],
      서구: [
        '양동',
        '농성동',
        '광천동',
        '유덕동',
        '치평동',
        '상무동',
        '화정동',
        '서창동',
        '금호동',
        '풍암동',
        '동천동',
      ],
      남구: [
        '양림동',
        '방림동',
        '봉선동',
        '사직동',
        '송암동',
        '월산동',
        '백운동',
        '주월동',
        '효덕동',
        '진월동',
        '송정동',
      ],
      북구: [
        '중흥동',
        '중앙동',
        '임동',
        '신안동',
        '용봉동',
        '운암동',
        '동림동',
        '우산동',
        '풍향동',
        '문화동',
        '문흥동',
        '각화동',
        '두암동',
        '삼각동',
        '일곡동',
        '매곡동',
        '오치동',
        '석곡동',
        '건국동',
        '양산동',
        '신용동',
        '태령동',
      ],
      광산구: [
        '송정동',
        '도산동',
        '신흥동',
        '우산동',
        '월곡동',
        '비아동',
        '첨단동',
        '신가동',
        '수완동',
        '하남동',
        '임곡동',
        '동곡동',
        '삼도동',
        '본량동',
        '신창동',
        '평동',
      ],
    },
    대전시: {
      동구: [
        '중앙동',
        '효동',
        '신인동',
        '판암동',
        '용운동',
        '대동',
        '자양동',
        '가양동',
        '용전동',
        '성남동',
        '홍도동',
        '삼성동',
        '정동',
        '대청동',
        '비래동',
      ],
      중구: [
        '은행선화동',
        '목동',
        '중촌동',
        '대흥동',
        '문창동',
        '석교동',
        '대사동',
        '부사동',
        '용두동',
        '오류동',
        '태평동',
        '유천동',
        '문화동',
        '산성동',
      ],
      서구: [
        '복수동',
        '도마동',
        '정림동',
        '변동',
        '용문동',
        '탄방동',
        '둔산동',
        '갈마동',
        '월평동',
        '가수원동',
        '도안동',
        '관저동',
        '기성동',
        '내동',
        '만년동',
        '가장동',
        '괴정동',
      ],
      유성구: [
        '진잠동',
        '학하동',
        '덕명동',
        '계산동',
        '관평동',
        '노은동',
        '신성동',
        '온천동',
        '원신흥동',
        '전민동',
        '구즉동',
        '대동',
        '문지동',
        '송강동',
        '관저동',
      ],
      대덕구: [
        '오정동',
        '대화동',
        '회덕동',
        '비래동',
        '송촌동',
        '중리동',
        '신탄진동',
        '덕암동',
        '목상동',
        '석봉동',
        '법동',
      ],
    },
    울산시: {
      중구: [
        '학성동',
        '반구동',
        '복산동',
        '중앙동',
        '우정동',
        '태화동',
        '다운동',
        '병영동',
        '약사동',
        '성안동',
      ],
      남구: [
        '삼산동',
        '달동',
        '신정동',
        '옥동',
        '대현동',
        '수암동',
        '선암동',
        '야음장생포동',
        '무거동',
        '삼호동',
      ],
      동구: ['방어동', '일산동', '화정동', '대송동', '전하동', '남목동'],
      북구: ['농소동', '강동동', '효문동', '송정동', '양정동', '염포동'],
      울주군: [
        '온양읍',
        '언양읍',
        '청량읍',
        '삼남읍',
        '범서읍',
        '서생면',
        '온산읍',
        '웅촌면',
        '두동면',
        '두서면',
        '상북면',
        '삼동면',
      ],
    },
    세종특별자치도: {
      조치원읍: [
        '신안리',
        '서창리',
        '신흥리',
        '침산리',
        '죽림리',
        '정리',
        '평리',
        '남리',
        '번암리',
        '원리',
        '교리',
        '봉산리',
        '상리',
      ],
      연기면: ['연기리', '보통리', '눌왕리', '수산리'],
      연동면: ['부동리', '송용리', '노송리', '내판리', '예양리'],
      부강면: ['부강리', '문곡리', '노호리', '등곡리', '연서리'],
      금남면: [
        '금천리',
        '발산리',
        '봉기리',
        '부리',
        '성덕리',
        '용포리',
        '진의리',
        '성리',
      ],
      장군면: ['금암리', '대교리', '송문리', '용암리', '하봉리', '봉안리'],
      연서면: ['부동리', '와촌리', '신대리', '월하리', '연서리'],
      전의면: ['전의리', '읍내리', '다방리', '복전리', '신방리'],
      전동면: ['전동리', '송석리', '송촌리', '동신리', '서원리'],
      소정면: ['소정리', '운당리', '대곡리', '고등리'],
      한솔동: ['한솔동'],
      새롬동: ['새롬동'],
      나성동: ['나성동'],
      도담동: ['도담동'],
      어진동: ['어진동'],
      해밀동: ['해밀동'],
      아름동: ['아름동'],
      종촌동: ['종촌동'],
      고운동: ['고운동'],
      소담동: ['소담동'],
      반곡동: ['반곡동'],
      보람동: ['보람동'],
      대평동: ['대평동'],
      다정동: ['다정동'],
    },
    경기도: {
      수원시: ['장안구', '권선구', '팔달구', '영통구'],
      성남시: ['수정구', '중원구', '분당구'],
      의정부시: [
        '의정부동',
        '호원동',
        '장암동',
        '신곡동',
        '송산동',
        '자일동',
        '가능동',
        '녹양동',
      ],
      안양시: ['만안구', '동안구'],
      부천시: ['원미구', '소사구', '오정구'],
      광명시: ['광명동', '철산동', '하안동', '소하동', '학온동'],
      평택시: [
        '팽성읍',
        '안중읍',
        '포승읍',
        '청북읍',
        '진위면',
        '서탄면',
        '고덕면',
        '오성면',
        '비전동',
        '신평동',
        '원평동',
        '세교동',
        '죽백동',
        '동삭동',
      ],
      동두천시: ['생연동', '중앙동', '보산동', '불현동', '송내동', '상패동'],
      안산시: ['단원구', '상록구'],
      고양시: ['덕양구', '일산동구', '일산서구'],
      과천시: [
        '중앙동',
        '갈현동',
        '문원동',
        '별양동',
        '부림동',
        '과천동',
        '원문동',
      ],
      구리시: ['갈매동', '동구동', '수택동', '교문동'],
      남양주시: [
        '와부읍',
        '진접읍',
        '화도읍',
        '수동면',
        '조안면',
        '별내면',
        '진건읍',
        '오남읍',
        '퇴계원면',
        '금곡동',
        '양정동',
        '다산동',
      ],
      오산시: ['대원동', '남촌동', '중앙동', '초평동', '신장동'],
      시흥시: [
        '정왕동',
        '군자동',
        '월곶동',
        '대야동',
        '신천동',
        '방산동',
        '신현동',
        '배곧동',
        '목감동',
        '은행동',
        '과림동',
        '매화동',
        '거모동',
      ],
      군포시: [
        '금정동',
        '산본동',
        '재궁동',
        '수리동',
        '오금동',
        '당동',
        '당정동',
        '대야동',
      ],
      의왕시: [
        '고천동',
        '부곡동',
        '오전동',
        '내손동',
        '청계동',
        '학의동',
        '포일동',
      ],
      하남시: [
        '천현동',
        '미사동',
        '풍산동',
        '덕풍동',
        '감북동',
        '감일동',
        '감이동',
      ],
      용인시: ['처인구', '기흥구', '수지구'],
      파주시: [
        '금촌동',
        '월롱면',
        '파주읍',
        '교하동',
        '운정동',
        '문산읍',
        '법원읍',
        '적성면',
        '광탄면',
      ],
      이천시: [
        '창전동',
        '중리동',
        '관고동',
        '증포동',
        '백사면',
        '마장면',
        '대월면',
        '호법면',
        '장호원읍',
        '부발읍',
        '설성면',
        '신둔면',
      ],
      안성시: [
        '금광면',
        '보개면',
        '서운면',
        '일죽면',
        '죽산면',
        '삼죽면',
        '고삼면',
        '대덕면',
        '미양면',
        '안성동',
      ],
      김포시: [
        '김포동',
        '사우동',
        '풍무동',
        '양촌읍',
        '구래동',
        '마산동',
        '운양동',
        '장기본동',
        '장기동',
        '통진읍',
        '대곶면',
        '하성면',
      ],
      화성시: [
        '병점동',
        '반송동',
        '동탄동',
        '향남읍',
        '양감면',
        '정남면',
        '팔탄면',
        '기배동',
        '비봉면',
        '마도면',
        '송산면',
      ],
      광주시: [
        '오포읍',
        '곤지암읍',
        '초월읍',
        '도척면',
        '퇴촌면',
        '남종면',
        '중대동',
        '경안동',
        '송정동',
        '쌍령동',
        '탄벌동',
        '장지동',
      ],
      양주시: [
        '양주동',
        '백석읍',
        '은현면',
        '남면',
        '광적면',
        '봉양동',
        '회정동',
      ],
      포천시: [
        '소흘읍',
        '포천읍',
        '영북면',
        '영중면',
        '양문동',
        '운천면',
        '신읍동',
        '가산면',
        '화현면',
      ],
      여주시: [
        '여흥동',
        '중앙동',
        '흥천면',
        '가남읍',
        '능서면',
        '대신면',
        '강천면',
        '북내면',
        '산북면',
      ],
      연천군: [
        '연천읍',
        '전곡읍',
        '청산면',
        '백학면',
        '미산면',
        '왕징면',
        '신서면',
        '장남면',
      ],
      가평군: ['가평읍', '설악면', '청평면', '상면', '조종면', '북면'],
      양평군: [
        '양평읍',
        '옥천면',
        '강하면',
        '서종면',
        '단월면',
        '청운면',
        '양동면',
        '지평면',
        '용문면',
        '개군면',
      ],
    },
    강원특별자치도: {
      춘천시: [
        '신북읍',
        '동내면',
        '남면',
        '동면',
        '서면',
        '사북면',
        '신사우동',
        '소양동',
      ],
      원주시: [
        '문막읍',
        '부론면',
        '소초면',
        '지정면',
        '호저면',
        '개운동',
        '명륜동',
        '단구동',
      ],
      강릉시: [
        '강남동',
        '성덕동',
        '경포동',
        '옥계면',
        '주문진읍',
        '연곡면',
        '사천면',
        '포남동',
      ],
      동해시: [
        '천곡동',
        '북삼동',
        '송정동',
        '삼화동',
        '망상동',
        '이도동',
        '발한동',
      ],
      태백시: [
        '황지동',
        '상장동',
        '삼수동',
        '문곡소도동',
        '장성동',
        '구문소동',
      ],
      속초시: [
        '영랑동',
        '동명동',
        '교동',
        '금호동',
        '노학동',
        '대포동',
        '설악동',
      ],
      삼척시: [
        '도계읍',
        '원덕읍',
        '근덕면',
        '하장면',
        '노곡면',
        '성내동',
        '정라동',
      ],
      홍천군: ['홍천읍', '화촌면', '두촌면', '내촌면', '서석면', '내면'],
      횡성군: ['횡성읍', '우천면', '안흥면', '둔내면', '갑천면', '청일면'],
      영월군: ['영월읍', '상동읍', '김삿갓면', '북면', '주천면', '무릉도원면'],
      평창군: [
        '평창읍',
        '미탄면',
        '방림면',
        '대화면',
        '봉평면',
        '용평면',
        '진부면',
      ],
      정선군: [
        '정선읍',
        '고한읍',
        '사북읍',
        '남면',
        '북평면',
        '임계면',
        '화암면',
      ],
      철원군: ['철원읍', '김화읍', '갈말읍', '동송읍', '서면', '근남면'],
      화천군: ['화천읍', '간동면', '하남면', '사내면', '상서면'],
      양구군: ['양구읍', '국토정중앙면', '동면', '방산면', '해안면'],
      인제군: ['인제읍', '남면', '북면', '기린면', '서화면', '상남면'],
      고성군: ['간성읍', '거진읍', '현내면', '죽왕면', '토성면', '수동면'],
      양양군: ['양양읍', '서면', '손양면', '현남면', '현북면', '강현면'],
    },
    충청북도: {
      청주시: ['상당구', '서원구', '흥덕구', '청원구'],
      충주시: ['교현동', '문화동', '호암동', '용산동', '칠금동', '연수동'],
      제천시: ['청전동', '장락동', '강제동', '신백동', '용두동', '고암동'],
      보은군: ['보은읍', '속리산면', '장안면', '마로면', '탄부면', '삼승면'],
      옥천군: ['옥천읍', '동이면', '안남면', '안내면', '청성면', '청산면'],
      영동군: ['영동읍', '매곡면', '상촌면', '양산면', '용화면', '학산면'],
      증평군: ['증평읍', '도안면'],
      진천군: ['진천읍', '덕산읍', '초평면', '문백면', '백곡면', '이월면'],
      괴산군: ['괴산읍', '감물면', '장연면', '청천면', '칠성면', '문광면'],
      음성군: ['음성읍', '금왕읍', '소이면', '원남면', '맹동면', '생극면'],
      단양군: ['단양읍', '매포읍', '대강면', '가곡면', '적성면', '어상천면'],
    },
    충청남도: {
      천안시: ['동남구', '서북구'],
      공주시: ['신관동', '웅진동', '유구읍', '사곡면', '신풍면', '정안면'],
      보령시: ['대천동', '주교면', '청라면', '남포면', '주산면', '오천면'],
      아산시: ['온양1동', '온양2동', '온양3동', '온양4동', '배방읍', '탕정면'],
      서산시: ['부춘동', '석남동', '대산읍', '지곡면', '팔봉면', '성연면'],
      논산시: ['강경읍', '부적면', '연무읍', '성동면', '광석면', '상월면'],
      계룡시: ['두마면', '엄사면', '신도안면'],
      당진시: ['송악읍', '합덕읍', '신평면', '우강면', '정미면', '면천면'],
      금산군: ['금산읍', '금성면', '제원면', '부리면', '군북면', '남일면'],
      부여군: ['부여읍', '규암면', '은산면', '외산면', '내산면', '초촌면'],
      서천군: ['서천읍', '마서면', '판교면', '문산면', '비인면', '서면'],
      청양군: ['청양읍', '정산면', '목면', '청남면', '장평면', '남양면'],
      홍성군: ['홍성읍', '광천읍', '홍북읍', '금마면', '홍동면', '장곡면'],
      예산군: ['예산읍', '삽교읍', '대술면', '대흥면', '응봉면', '덕산면'],
      태안군: ['태안읍', '안면읍', '고남면', '남면', '근흥면', '소원면'],
    },
    전북특별자치도: {
      전주시: ['완산구', '덕진구'],
      군산시: ['나운동', '수송동', '옥산면', '옥구읍', '미성동'],
      익산시: ['모현동', '송학동', '부송동', '영등동', '금마면', '왕궁면'],
      정읍시: ['연지동', '수성동', '상동', '신태인읍', '북면', '소성면'],
      남원시: ['도통동', '죽항동', '금지면', '이백면', '송동면', '주생면'],
      김제시: ['요촌동', '검산동', '금산면', '봉남면', '성덕면', '용지면'],
      완주군: ['삼례읍', '봉동읍', '용진읍', '고산면', '상관면', '소양면'],
      진안군: ['진안읍', '용담면', '안천면', '백운면', '성수면', '부귀면'],
      무주군: ['무주읍', '안성면', '적상면', '설천면', '부남면'],
      장수군: ['장수읍', '산서면', '번암면', '장계면', '천천면', '계남면'],
      임실군: ['임실읍', '청웅면', '운암면', '신평면', '성수면', '강진면'],
      순창군: ['순창읍', '인계면', '동계면', '풍산면', '금과면', '팔덕면'],
      고창군: ['고창읍', '아산면', '무장면', '공음면', '흥덕면', '성송면'],
      부안군: ['부안읍', '줄포면', '하서면', '계화면', '동진면', '백산면'],
    },
    전라남도: {
      목포시: [
        '용당1동',
        '용당2동',
        '연산동',
        '원산동',
        '대양동',
        '이로동',
        '상동',
        '하당동',
      ],
      여수시: [
        '여서동',
        '문수동',
        '둔덕동',
        '미평동',
        '오림동',
        '안산동',
        '연등동',
        '만흥동',
      ],
      순천시: [
        '향동',
        '매곡동',
        '도사동',
        '왕조1동',
        '왕조2동',
        '덕연동',
        '연향동',
        '해룡면',
      ],
      나주시: [
        '빛가람동',
        '송월동',
        '남평읍',
        '다시면',
        '문평면',
        '금천면',
        '공산면',
        '동강면',
      ],
      광양시: [
        '광양읍',
        '중마동',
        '골약동',
        '태인동',
        '봉강면',
        '옥곡면',
        '진상면',
        '진월면',
      ],
      담양군: [
        '담양읍',
        '봉산면',
        '고서면',
        '남면',
        '대덕면',
        '무정면',
        '금성면',
        '가사문학면',
      ],
      곡성군: [
        '곡성읍',
        '삼기면',
        '석곡면',
        '겸면',
        '오곡면',
        '오산면',
        '옥과면',
        '입면',
      ],
      구례군: [
        '구례읍',
        '문척면',
        '간전면',
        '토지면',
        '마산면',
        '광의면',
        '용방면',
        '산동면',
      ],
      고흥군: [
        '고흥읍',
        '도양읍',
        '풍양면',
        '도덕면',
        '금산면',
        '영남면',
        '점암면',
        '동강면',
      ],
      보성군: [
        '보성읍',
        '벌교읍',
        '노동면',
        '겸백면',
        '율어면',
        '복내면',
        '문덕면',
        '조성면',
      ],
      화순군: [
        '화순읍',
        '한천면',
        '춘양면',
        '청풍면',
        '이양면',
        '능주면',
        '도곡면',
        '도암면',
      ],
      장흥군: [
        '장흥읍',
        '관산읍',
        '대덕읍',
        '안양면',
        '용산면',
        '장동면',
        '장평면',
        '유치면',
      ],
      강진군: [
        '강진읍',
        '군동면',
        '칠량면',
        '대구면',
        '도암면',
        '신전면',
        '성전면',
        '작천면',
      ],
      해남군: [
        '해남읍',
        '삼산면',
        '화산면',
        '현산면',
        '송지면',
        '북일면',
        '북평면',
        '옥천면',
      ],
      영암군: [
        '영암읍',
        '삼호읍',
        '덕진면',
        '금정면',
        '신북면',
        '시종면',
        '도포면',
        '군서면',
      ],
      무안군: [
        '무안읍',
        '일로읍',
        '삼향읍',
        '몽탄면',
        '청계면',
        '현경면',
        '망운면',
        '운남면',
      ],
      함평군: [
        '함평읍',
        '손불면',
        '신광면',
        '엄다면',
        '대동면',
        '학교면',
        '나산면',
        '해보면',
      ],
      영광군: [
        '영광읍',
        '백수읍',
        '홍농읍',
        '대마면',
        '묘량면',
        '불갑면',
        '군서면',
        '군남면',
      ],
      장성군: [
        '장성읍',
        '삼계면',
        '삼서면',
        '황룡면',
        '진원면',
        '남면',
        '동화면',
        '북이면',
      ],
      완도군: [
        '완도읍',
        '금일읍',
        '금당면',
        '신지면',
        '고금면',
        '약산면',
        '청산면',
        '소안면',
      ],
      진도군: [
        '진도읍',
        '군내면',
        '고군면',
        '의신면',
        '임회면',
        '지산면',
        '조도면',
      ],
      신안군: [
        '압해읍',
        '자은면',
        '비금면',
        '도초면',
        '흑산면',
        '임자면',
        '하의면',
        '신의면',
      ],
    },
    경상북도: {
      포항시: ['남구', '북구'],
      경주시: [
        '건천읍',
        '안강읍',
        '외동읍',
        '강동면',
        '천북면',
        '용강동',
        '황성동',
      ],
      김천시: [
        '아포읍',
        '농소면',
        '남면',
        '개령면',
        '지례면',
        '감문면',
        '성내동',
      ],
      안동시: [
        '풍산읍',
        '와룡면',
        '임동면',
        '임하면',
        '길안면',
        '남후면',
        '용상동',
        '송하동',
      ],
      구미시: [
        '선산읍',
        '고아읍',
        '도개면',
        '산동면',
        '공단동',
        '형곡동',
        '지산동',
      ],
      영주시: [
        '풍기읍',
        '순흥면',
        '부석면',
        '단산면',
        '봉현면',
        '가흥동',
        '휴천동',
      ],
      영천시: ['금호읍', '청통면', '신녕면', '화산면', '중앙동', '완산동'],
      상주시: [
        '함창읍',
        '중동면',
        '모동면',
        '모서면',
        '화서면',
        '남원동',
        '동문동',
      ],
      문경시: ['문경읍', '마성면', '농암면', '영순면', '산양면', '점촌동'],
      경산시: [
        '하양읍',
        '진량읍',
        '압량읍',
        '자인면',
        '와촌면',
        '중방동',
        '사동',
      ],
      군위군: ['군위읍', '소보면', '효령면', '부계면', '우보면', '산성면'],
      의성군: [
        '의성읍',
        '단촌면',
        '점곡면',
        '옥산면',
        '춘산면',
        '구천면',
        '금성면',
      ],
      청송군: ['청송읍', '주왕산면', '부남면', '현서면', '현동면', '안덕면'],
      영양군: ['영양읍', '일월면', '수비면', '석보면', '입암면'],
      영덕군: ['영덕읍', '강구면', '남정면', '달산면', '지품면', '축산면'],
      청도군: ['화양읍', '청도읍', '각남면', '풍각면', '이서면', '운문면'],
      고령군: ['대가야읍', '덕곡면', '운수면', '성산면', '다산면', '우곡면'],
      성주군: ['성주읍', '선남면', '용암면', '금수면', '대가면', '월항면'],
      칠곡군: ['왜관읍', '북삼읍', '석적읍', '약목면', '지천면'],
      예천군: ['예천읍', '용문면', '효자면', '은풍면', '용궁면'],
      봉화군: ['봉화읍', '물야면', '재산면', '봉성면', '상운면'],
      울진군: ['울진읍', '후포면', '죽변면', '북면', '평해읍'],
      울릉군: ['울릉읍', '서면', '북면'],
    },
    경상남도: {
      창원시: ['의창구', '성산구', '마산합포구', '마산회원구', '진해구'],
      김해시: [
        '장유1동',
        '장유2동',
        '장유3동',
        '내외동',
        '칠산서부동',
        '삼안동',
        '북부동',
        '동상동',
      ],
      양산시: [
        '물금읍',
        '동면',
        '상북면',
        '하북면',
        '중앙동',
        '강서동',
        '서창동',
      ],
      진주시: [
        '문산읍',
        '내동면',
        '대평면',
        '상봉동',
        '성북동',
        '서부동',
        '중앙동',
        '평거동',
      ],
      거제시: [
        '옥포1동',
        '옥포2동',
        '아주동',
        '장승포동',
        '능포동',
        '고현동',
        '연초면',
        '장목면',
      ],
      통영시: [
        '도산면',
        '광도면',
        '욕지면',
        '도남동',
        '정량동',
        '북신동',
        '무전동',
      ],
      사천시: [
        '사남면',
        '정동면',
        '곤명면',
        '벌용동',
        '향촌동',
        '동서금동',
        '동서동',
      ],
      밀양시: [
        '내이동',
        '삼문동',
        '용이동',
        '교동',
        '가곡동',
        '단장면',
        '상동면',
        '삼랑진읍',
      ],
      함안군: [
        '가야읍',
        '칠서면',
        '칠북면',
        '대산면',
        '법수면',
        '산인면',
        '여항면',
        '칠곡면',
      ],
      창녕군: [
        '남지읍',
        '대합면',
        '이방면',
        '대지면',
        '계성면',
        '유어면',
        '대지면',
        '부곡면',
      ],
      고성군: [
        '고성읍',
        '삼산면',
        '하이면',
        '마암면',
        '대가면',
        '영오면',
        '개천면',
        '영현면',
      ],
      남해군: [
        '남해읍',
        '삼동면',
        '고현면',
        '설천면',
        '이동면',
        '창선면',
        '상주면',
        '미조면',
      ],
      하동군: [
        '하동읍',
        '화개면',
        '악양면',
        '적량면',
        '횡천면',
        '진교면',
        '양보면',
        '북천면',
      ],
      산청군: [
        '산청읍',
        '차황면',
        '오부면',
        '생초면',
        '금서면',
        '삼장면',
        '시천면',
        '단성면',
      ],
      함양군: [
        '함양읍',
        '마천면',
        '휴천면',
        '유림면',
        '수동면',
        '지곡면',
        '안의면',
        '서상면',
      ],
      거창군: [
        '거창읍',
        '주상면',
        '웅양면',
        '고제면',
        '북상면',
        '신원면',
        '가조면',
        '남상면',
      ],
      합천군: [
        '합천읍',
        '봉산면',
        '삼가면',
        '가야면',
        '쌍책면',
        '청덕면',
        '묘산면',
        '가회면',
      ],
    },
    제주특별자치도: {
      제주시: [
        '한림읍',
        '애월읍',
        '구좌읍',
        '조천읍',
        '한경면',
        '추자면',
        '우도면',
        '일도1동',
        '일도2동',
        '이도1동',
        '이도2동',
        '삼도1동',
        '삼도2동',
        '용담1동',
        '용담2동',
        '건입동',
        '화북동',
        '삼양동',
        '봉개동',
        '아라동',
        '오라동',
        '연동',
        '노형동',
        '외도동',
        '이호동',
        '도두동',
      ],
      서귀포시: [
        '대정읍',
        '남원읍',
        '성산읍',
        '안덕면',
        '표선면',
        '송산동',
        '정방동',
        '중앙동',
        '천지동',
        '효돈동',
        '영천동',
        '동홍동',
        '서홍동',
        '대륜동',
        '대천동',
        '중문동',
        '예래동',
      ],
    },
  },
};

export default CITY_LIST;
